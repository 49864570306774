import UserPermissions from './userPermissionsDictionary';

const types = {
  ADVERTISER: 'ADVERTISER',
  AGENCY: 'AGENCY',
};

// Account type specific permissions
// Only Agency can see Advertiser tab
const permissions = {
  ADVERTISER: [],
  AGENCY: [UserPermissions.types.VISTA_ADVERTISER_MULTIPLE],
};

const dictionary = [
  { id: types.ADVERTISER, name: 'Brand/Business' },
  { id: types.AGENCY, name: 'Agency' },
];

export default {
  types,
  dictionary,
  permissions,
};
